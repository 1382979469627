<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <!--      <bookmarks />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto w-100">
      <a href="https://enzamode.ir/" target="_blank" class="mr-auto">
        <img src="/logo.png" width="100" height="50" alt="">
      </a>
      <!--      <locale />-->
      <dark-toggler class="d-none d-lg-block"/>
      <!--      <search-bar />-->
      <!--      <cart-dropdown />-->
      <!--      <notification-dropdown />-->
      <user-dropdown/>
    </b-navbar-nav>

  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import {mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  watch: {
    getNotificationObj(val) {
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: ``,
          icon: 'CheckIcon',
          variant: 'primary',
          text: ' شما یک تیکت جدید دارید ',
          link:`/apps/ticketDetail/${val.objectId}`,
          linkText:` تیکت شماره ${val.objectId} `
        },
      })
    }
  },
  methods: {

  },
  computed: {
    ...mapGetters(['getNotificationObj'])
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
}
</script>
